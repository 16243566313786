<template>
  <div class="projectlists">
    <div v-if="!vMobile">
    <waterfall
      line="v"
      :watch="imgsArr"
      :align="align"
      :line-gap="screenW1"
      :grow="grow"
      auto-resize:true
      v-if="imgsArr.length > 0"
    >
      <!-- each component is wrapped by a waterfall slot -->
      <waterfall-slot
        v-for="(item, index) in imgsArr"
        v-if="item.img_width > 0 && item.img_height > 0"
        :width="item.img_width"
        :height="item.img_height+ item.img_width*0.5 "
        :order="index"
        :key="item.id"
      >
        <router-link
          v-if="ajaxUrl=='/wc/proLists'"
          class="block"
          :to="{ name: 'projectInfo', params: { id: item.id }}"
        >
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
        <router-link v-if="ajaxUrl=='/wc/news'" :to="{ name: 'newsInfo', params: { id: item.id }}">
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
        <router-link
          v-if="ajaxUrl=='/wc/dongs'"
          :to="{ name: 'observeInfo', params: { id: item.id }}"
        >
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
      </waterfall-slot>
    </waterfall>
    <div v-else class="text-center fz-18">暂无文章</div>
    </div>
    <div class="mobileBox" v-else>
      <ul>
        <li v-for="(item, index) in imgsArr" :key="index">
          <router-link
          v-if="ajaxUrl=='/wc/proLists'"
          :to="{ name: 'projectInfo', params: { id: item.id }}"
        >
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
        <router-link v-if="ajaxUrl=='/wc/news'" :to="{ name: 'newsInfo', params: { id: item.id }}">
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
        <router-link
          v-if="ajaxUrl=='/wc/dongs'"
          :to="{ name: 'observeInfo', params: { id: item.id }}"
        >
          <div class="imgList">
            <span>
              <img :src="item.img" alt />
            </span>
            <p class="projectTit m-t-15 ">{{item.title}}</p>
            <p class="mal">{{item.sub_title}}</p>
          </div>
        </router-link>
        </li>
      </ul>
      
    </div>
    <div class="goTop">
      <div @click="goTop" class="goTopBox">
        <img src="../assets/images/icon_gotop.png" alt />
        <p>回到顶部</p>
      </div>
    </div>
  </div>
</template>

<script>
import Waterfall from "vue-waterfall/lib/waterfall";
import WaterfallSlot from "vue-waterfall/lib/waterfall-slot";
export default {
  data() {
    return {
      align: "left",
      screenW1: 0,
      imgsArr: [],
      group: 0,
      grow: [1, 1, 1, 1],
    };
  },
  beforeCreate() {},
  computed: {
    type_id() {
      if (!this.$route.query.type_id) {
        return 0;
      }
      return this.$route.query.type_id;
    },
    year() {
      if (!this.$route.params.year) {
        return '';
      }
      return this.$route.params.year;
    },
    vMobile() {
      let ua = navigator.userAgent;
      let ipad = ua.match(/(iPad).*OS\s([\d_]+)/)
      let isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/)
      let isAndroid = ua.match(/(Android)\s+([\d.]+)/)
      let isMobile = isIphone || isAndroid;
      //判断
      if (isMobile) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: ["projectlists", "ajaxUrl"],
  components: {
    Waterfall,
    WaterfallSlot,
  },
  methods: {
    // async getImageH(item) {
    //   let img = new Image();
    //   img.src = item.img;
    //   return await new Promise((resolve, reject) => {
    //     img.onload = () => {
    //         // 打印
    //         console.log(img.height)
    //         resolve(img.width)
    //     };
    //   })
      
    //   return img.height
    // },
    // async getImageW(item) {
    //   let img = new Image();
    //   img.src = item.img;
    //   return await new Promise((resolve, reject) => {
    //     img.onload = () => {
    //         // 打印
    //         resolve(img.height)
    //     };
    //   })
    // },
    getData() {
      this.axios
        .get(
          this.GLOBAL.serverSrc +
            this.ajaxUrl +
            "?group=" +
            this.group +
            "&&type_id=" +
            this.type_id +
             "&&year=" +
            this.year
        )
        .then((res) => {
          // this.imgsArr = this.imgsArr.concat(res.data.data.data);
          this.imgsArr = res.data.data.data;
          this.group++;
        });
    },
    goTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  created() {
   
  },
  watch: {
    $route: {
      handler(nv, ov) {
        this.$nextTick( () => {
          this.getData();
        })
        
      },
    },
    deep: true,
  },
  mounted() {
    this.getData();
    
  },
};
</script>

<style lang="scss" scoped>
.block {display: block;}
.projectlists {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;
  margin: 0 -12.5px;
  margin-top: 33px;
  .mobileBox{
    width: 100%;
    margin-bottom: 25px;
  }
  .goTop {
    // position: absolute;
    // bottom: 0px;
    height: 60px;
    width: 100%;
    margin-top:25px;
    text-align: center;
    .goTopBox {
      cursor: pointer;
    }
    img {
      width: 40px;
      height: 40px;
      -webkit-animation: box .8s infinite !important;
      animation: box .8s infinite !important;
      opacity: 1 !important;
      position: relative;
    }
    p {
      font-size: 12px;
      color: #000;
      margin-top: 6px;
    }

    // .goTopBox img:hover {
    //   position: relative;
    //   -webkit-animation: box 1s infinite !important;
    //   animation: box 1s infinite !important;
    //   opacity: 1 !important;
    // }
    // .goTopBox img:hover {
    //   position: relative;
    //   -webkit-animation: box 1s infinite !important;
    //   animation: box 1s infinite !important;
    //   opacity: 1 !important;
    // }
    @-webkit-keyframes box {
      0% {
        bottom: 0px;
      }

      25% {
        bottom: 0px;
      }
      50% {
        bottom: 10px;
      }
      75% {
        bottom: 0px;
      }

      100% {
        bottom: 0px;
      }
    }
    @keyframes box {
      0% {
        bottom: 0px;
      }

      25% {
        bottom: 0px;
      }
      50% {
        bottom: 10px;
      }
      75% {
        bottom: 0px;
      }

      100% {
        bottom: 0px;
      }
    }
  }
  .projectTit {
    position: relative;
  }
  .projectTit:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    box-shadow: inset 0 -15px #0066b2;
    opacity: 0;
    transition: all ease .5s;
  }
  .imgList:hover .projectTit:after {
    height: 90%;
    opacity: .45;
  }

  .imgList:hover img {
    transform: scale(1.1);
  }
  .imgList {
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 12.5px;

    span {
      width: 100%;
      display: block;
      height: auto;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }
    .projectTit {
      color: #000;
      font-size: 16px;
      overflow: hidden;
      font-weight: 600;
      display: inline-block;
      padding: 0 10px;
    }
    .mal {
      color: #B3B3B3;
      font-size: 12px;
      overflow: hidden;
      padding: 0 10px;
      line-height: 1.5;
    }
  }
}
@media (max-width: 768px){
  .projectlists{
    overflow-y: auto;
  }
  .imgList {margin-bottom: 15px;}
}
</style>